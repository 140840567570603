.class-font-bold {
    font-weight: 600 !important;
}

@media (min-width: 640px) and (max-width:979px) {
    .class-font-bold{
        font-size: 1.4583rem !important;
    }
    .class-title-our-story {
        font-size: 2.4583rem !important;
    }
}

@media (max-width: 640px) {
    .class-font-bold {
        font-size: 1.25rem !important;
    }
    .class-title-our-story {
        font-size: 2.25rem !important;
    }
}

.MuiGrid-spacing-xs-10 > .MuiGrid-item {
    padding: 0 40px !important;
}