
::placeholder {
    color: $color-black;
    opacity: 1; /* Firefox */
  }
  
  :-ms-input-placeholder { /* Internet Explorer 10-11 */
   color: $color-black;
  }
  
  ::-ms-input-placeholder { /* Microsoft Edge */
   color: $color-black;
  }

/* width */
::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: $color-black;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: $color-orange;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: $color-orange-light;
}