// .class-dropDownAnimation{
//     animation: menuAnimationReverse 0.2s;
//     opacity: 0;
//     height: 0;
//     top: -100px;
// }

// .class-dropUpAnimation{
//     animation: menuAnimation 0.2s;
// }

// .CLASS-DELAY div:nth-child(3){
//     transition-delay: 0.3s !important;
// }

.class-dropUpAnimation > div {
  height: 84px;
  transition: height 0.5s;
}

.class-dropDownAnimation > div {
  height: 42px;
  transition: height 0.5s;
}

.class-dropUpAnimation > div .class-logo-h {
  height: 36px;
  transition: height 0.5s;
}

.class-dropDownAnimation > div .class-logo-h {
  height: 20px;
  transition: height 0.5s;
}

.CLASS-PROOF div:nth-child(3) {
  transition: opacity 237ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    transform 158ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  transition-duration: 0ms !important;
}

//Keyframes - Amination

@keyframes menuAnimation {
  from {
    opacity: 0;
    height: 0;
    top: -100px;
  }
  to {
    opacity: 1;
    height: 84px;
    top: 0px;
  }
}

@keyframes menuAnimationReverse {
  from {
    opacity: 1;
    height: 84px;
    top: 0px;
  }
  to {
    opacity: 0;
    height: 0;
    top: -100px;
  }
}

// homepage

.MuiGrid-root.MuiGrid-container.MuiGrid-item.MuiGrid-align-items-xs-center.MuiGrid-justify-content-xs-flex-end.MuiGrid-grid-xs-6 {
  animation: opacityAnimation 1s;
}

@keyframes opacityAnimation {
  from {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.class-growUpAnimation {
  animation: growUpAnimation2 4s;
  position: relative;
}

@keyframes growUpAnimation2 {
  from {
    opacity: 0;
    top: 46px;
  }
  to {
    opacity: 1;
    top: 0px;
  }
}

.MuiGrid-root.makeStyles-mainIndent-24.MuiGrid-item,
h1.MuiTypography-root.MuiTypography-h1 {
  animation: growUpAnimation 1s;
  position: relative;
}

@keyframes growUpAnimation {
  from {
    opacity: 0;
    top: 46px;
  }
  50% {
    opacity: 0;
    top: 46px;
  }
  to {
    opacity: 1;
    top: 0px;
  }
}

.class-growBottomAnimation {
  animation: growBottomAnimation 2s;
  position: relative;
}

@keyframes growBottomAnimation {
  from {
    opacity: 0;
    bottom: 46px;
  }
  to {
    opacity: 1;
    bottom: 0px;
  }
}

.MuiGrid-root.makeStyles-firstIndent-22.MuiGrid-item,
.MuiGrid-root.makeStyles-secondIndent-23.MuiGrid-item {
  animation: growLeftAnimation 2s;
  position: relative;
  animation-timing-function: ease;
}

@keyframes growLeftAnimation {
  from {
    opacity: 0;
    left: 146px;
  }
  50% {
    opacity: 0;
    left: 146px;
  }
  to {
    opacity: 0.11;
    left: 0px;
  }
}

.class-hero-image-animated {
  animation: heroImageAnimation 1s;
}

@keyframes heroImageAnimation {
  from {
    min-height: 0;
    opacity: 0;
  }
  to {
    min-height: 221px;
    opacity: 1;
  }
}

span.MuiTypography-root.makeStyles-preTitle-22.visible.fadeBottom.MuiTypography-overline,
.class-subTitle-EP {
  animation: opacityAnimation 1s;
}
h2.MuiTypography-root.makeStyles-title-24.visible.fadeBottom.MuiTypography-h2,
.class-title-EP {
  animation: opacityAnimation 2s;
}

/*INICIO PRUEBA ANIMATION CSS + JS*/
.fadeTop {
  opacity: 0;
  transform: translate(0, 10vh);
  transition: all 1s;
}

//Commente style for show the content from the first render of the page.
// .fadeBottom {
//   opacity: 0;
//   transform: translate(0, -10vh);
//   transition: all 1s;
// }

.fadeRight {
  opacity: 0;
  transform: translate(10vh, 0vh);
  transition: all 1s;
}

.fadeLeft {
  opacity: 0;
  transform: translate(-10vh, 0vh);
  transition: all 1s;
}

.visible {
  opacity: 1;
  transform: translate(0, 0);
}
/*FIN PRUEBA ANIMATION CSS + JS*/
