@import 'variable.scss';

html {
  scroll-behavior: smooth;
}

.class-TopButton {
  background: $color-orange !important;
  position: fixed !important;
  bottom: 4vh;
  right: 2vw;
  z-index: 2000;
  box-shadow: $shadow-base;

  svg {
    color: $color-cream;
  }
}

.class-TopButton.MuiIconButton-root:hover {
  background-color: $color-orange-dark !important;
}

.display-none {
  display: none !important;
}

.MuiGrid-root.makeStyles-sectionContainer-4.MuiGrid-container.MuiGrid-item.MuiGrid-direction-xs-column.MuiGrid-align-items-xs-center.MuiGrid-justify-content-xs-center,
.MuiGrid-root.jss4.MuiGrid-container.MuiGrid-item.MuiGrid-direction-xs-column.MuiGrid-align-items-xs-center.MuiGrid-justify-content-xs-center {
  margin: 0;

  .MuiGrid-root.makeStyles-root-18.MuiGrid-container.MuiGrid-spacing-xs-1.MuiGrid-item.MuiGrid-direction-xs-column,
  .MuiGrid-root.makeStyles-root-18.class-blogSlug.MuiGrid-container.MuiGrid-item.MuiGrid-direction-xs-column.MuiGrid-grid-xs-12.MuiGrid-grid-md-10,
  .MuiGrid-root.jss18.class-blogSlug.MuiGrid-container.MuiGrid-item.MuiGrid-direction-xs-column.MuiGrid-grid-xs-12.MuiGrid-grid-md-10,
  .MuiGrid-root.jss18.MuiGrid-container.MuiGrid-spacing-xs-1.MuiGrid-item.MuiGrid-direction-xs-column,
  .class-pt-84px {
    padding-top: 84px;
  }
}

@media only screen and (max-width: 900px) {
  .MuiGrid-root.makeStyles-sectionContainer-4.MuiGrid-container.MuiGrid-item.MuiGrid-direction-xs-column.MuiGrid-align-items-xs-center.MuiGrid-justify-content-xs-center,
  .MuiGrid-root.jss4.MuiGrid-container.MuiGrid-item.MuiGrid-direction-xs-column.MuiGrid-align-items-xs-center.MuiGrid-justify-content-xs-center {
    .makeStyles-heroImage-20 {
      margin-left: -30px;
    }

    .makeStyles-mainImage-19 {
      margin-top: 84px;
    }
  }
}
