@import 'variable.scss';
@import 'blogSlug.scss';
@import 'scrollbar.scss';
@import 'AccordionBlogContent.scss';
@import 'animation.scss';

@import 'responsive.scss';

body {
    overflow-y: auto !important;
}