.MuiPaper-root.MuiAccordion-root.Mui-expanded.MuiAccordion-rounded.MuiPaper-elevation1.MuiPaper-rounded {
    z-index: 2;
}
.bg-display-none{
    position: absolute;
    background: transparent;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
}