@import 'variable.scss';

figure {
  display: flex;
  justify-content: center;
  align-content: center;

  img {
    max-width: 100%;
  }
}

.class-evergreenPage {
  .class-jumplink-menu {
    display: block;
    margin-bottom: 20px;
    width: 50%;

    svg.MuiSvgIcon-root {
      color: $color-cream;
    }

    div#panel1a-header {
      background: $color-orange;
      height: 48px !important;
      color: $color-cream;
      margin-top: 10px;
    }

    div#panel1a-header.MuiAccordionSummary-root.Mui-expanded {
      min-height: 48px !important;
      background: $color-orange-light;
      color: $color-cream;
    }

    .MuiAccordionDetails-root {
      display: flex;
      flex-direction: column;
      height: 100%;
      overflow-y: auto;
      padding: 0;

      span {
        padding: 10px 16px;
        background: $color-cream;
      }

      a {
        text-align: left;
        width: 100%;
        font-weight: bolder;
      }
    }
  }
  .titleH {
    .h2 {
      margin: 30px 0px;
    }
  }
}

.class-column4BlockContent {
  img {
    width: 100%;
  }

  .makeStyles-headerRender-45 {
    text-align: center;
  }
}

.class-portfolio-container {
  display: flex;
  justify-content: center;
  height: 0;
  margin-bottom: 20px !important;
}

.class-portfolio-title {
  position: relative !important;
  top: -90px;
  text-align: center;
  font-weight: 500 !important;
}

.class-portfolio-careers code {
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%),
    0px 1px 5px 0px rgb(0 0 0 / 12%);
  background: #fb7c6a;
  color: white;
  padding: 10px 15px;
  font-weight: bold;
}

.class-subscribe {
  display: grid !important;
  grid-gap: 20px;
  text-align: center;
  justify-content: center;
  margin-bottom: 100px !important;

  .hiddenDataForm {
    display: none;
  }
  .legendEmail {
    display: flex;
    color: #949494;
    padding-left: 10px;
    font-family: 'IBM Plex Sans, sans-serif';
    font-weight: 400;
    margin-top: -25px;
  }
  .validEmail {
    color: red;
    text-align: left;
    padding: 0px 0px 0px 10px;
    font-size: 12px;
  }
  input#Email {
    border: 1px solid #e0e0e0;
    padding: 10px 10px;
    width: 100%;
  }

  button {
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%),
      0px 1px 5px 0px rgb(0 0 0 / 12%);
    background: #fb7c6a;
    color: white;
    padding: 10px 15px;
    font-weight: bold;
    width: 50%;
    justify-self: center;

    &:hover {
      background: #333333;
    }
  }
}

@media screen and (max-width: '675px') and (min-width: '551px') {
  .class-evergreenPage {
    .class-jumplink-menu {
      width: 75%;
    }
  }
}

@media screen and (max-width: '550px') {
  .class-evergreenPage {
    .class-jumplink-menu {
      width: 100%;
    }
  }
}

@media screen and (max-width: '640px') and (min-width: '400px') {
  .class-column4BlockContent
    span.MuiTypography-root.makeStyles-preFormattedText-41.MuiTypography-body1 {
    display: grid;
    grid-template-columns: 50% 50%;

    span.MuiTypography-root.makeStyles-headerRender-45.MuiTypography-body1.MuiTypography-colorTextPrimary {
      grid-row: 2;
      text-align: center;

      .MuiGrid-root.MuiGrid-item {
        text-align: center;
      }
    }
  }
}
