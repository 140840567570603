.class-column2BlockContent {
  display: flex;
  align-items: center;

  & figure {
    display: flex;
    justify-content: center;

    img {
      width: 100%;
    }
  }
}

.class-column3BlockContent {
  padding-top: 0px !important;
  margin-top: -25px !important;
  margin-bottom: 10px !important;

  display: flex;
  justify-content: center;
  > div {
    .class-BlogBody {
      margin-top: -25px;

      figure {
        img {
          max-width: 80%;
        }
      }
      .titleH {
        margin-top: -30px;
      }
    }
  }
}

.class-logos-community figure {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 181px;

  img {
    width: 100%;
    height: fit-content;
  }
}

.class-column4PortfolioContent > div,
.class-column4PortfolioContent {
  position: relative;
  display: flex;
  align-items: center;
  min-height: 220px;
  min-width: 350px;
  width: 100%;

  &.withoutImg {
    min-height: 225px;
  }

  button {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: transparent;
    border: 1px solid #fb7b6a1c;
    border-radius: 10px;
    margin: 10px;
    box-shadow: 0px 10px 20px 2px #fb7b6a1c;
    z-index: 1;
  }
  button:hover {
    transition: box-shadow 1s;
    box-shadow: 0px 10px 20px 2px #fb7b6a4f;
  }
  p {
    padding: 0 10px;
    margin: 10px 0;
  }
  h2 {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    font-size: 1.3rem;
    text-align: center;
    padding: 20px;
    z-index: 0;
  }
  > .class-data {
    display: flex;
    flex-direction: column;
    text-align: left;
    align-items: baseline;
    margin-left: 25px;
    margin-top: 20px;
    padding-top: 5px;
    min-height: 200px;
  }
}

.class-grid-portfolio {
  display: grid !important;
  padding-bottom: 25px;

  label#demo-simple-select-standard-label {
    padding-left: 10px;
  }

  .MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded {
    margin-top: 44px;
  }

  .class-portfolioSelect {
    grid-row: 2;
    display: grid;
    grid-template-columns: 48px 200px;
    gap: 20px;
    justify-content: right;
    align-items: baseline;
    padding-bottom: 20px;
    border-bottom: 1px solid #e0e0e0;
    margin-bottom: 20px;

    div#demo-simple-select-standard {
      border: 1px solid #e0e0e0;
      border-radius: 10px;
      padding-left: 10px;
    }

    .MuiInput-underline:before,
    .MuiInput-underline:after {
      border-radius: 10px;
    }
  }
}

.class-animation {
  color: #333333;
  animation: fadeText 1s;
}

@keyframes fadeText {
  from {
    color: #33333300;
  }
  to {
    color: #333333;
  }
}

.class-animation-img {
  opacity: 1;
  animation: fadeImg 1s;

  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  z-index: 0;

  img {
    height: 80%;
    width: 80%;
  }
}

@keyframes fadeImg {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded {
  padding-top: 5px;
}

.class-grid-portfolio {
  display: grid !important;
  grid-template-columns: auto auto auto auto;
  width: auto;

  > .class-portfolioSelect,
  > .MuiGrid-root.makeStyles-layoutContainer-26.MuiGrid-container.MuiGrid-item.MuiGrid-grid-xs-12,
  > div:nth-child(2) {
    grid-column: 1 / span 4;
  }
}

@media only screen and (max-width: 1700px) {
  .class-grid-portfolio {
    display: grid !important;
    grid-template-columns: auto auto auto;
    width: auto;
  }

  .class-grid-portfolio > .class-portfolioSelect,
  .class-grid-portfolio
    > .MuiGrid-root.makeStyles-layoutContainer-26.MuiGrid-container.MuiGrid-item.MuiGrid-grid-xs-12,
  .class-grid-portfolio > div:nth-child(2) {
    grid-column: 1 / span 3;
  }
}

@media only screen and (max-width: 1515px) {
  .class-grid-portfolio {
    display: grid !important;
    grid-template-columns: auto auto;
    width: auto;
  }

  .class-grid-portfolio > .class-portfolioSelect,
  .class-grid-portfolio
    > .MuiGrid-root.makeStyles-layoutContainer-26.MuiGrid-container.MuiGrid-item.MuiGrid-grid-xs-12,
  .class-grid-portfolio > div:nth-child(2) {
    grid-column: 1 / span 2;
    margin-left: 15px;
  }

  .container-portfolio-card {
    margin-left: 40px;
  }
}

@media only screen and (max-width: 980px) {
  .class-column3BlockContent {
    > div {
      margin-top: -40px;
      .class-BlogBody {
        .titleH {
          margin-top: -65px;
          margin-bottom: 20px;
        }
      }
    }
  }
}

@media only screen and (min-width: 700px) and (max-width: 950px) {
  .class-grid-portfolio {
    margin-left: -45px !important;
  }
}

@media only screen and (max-width: 700px) {
  .class-grid-portfolio {
    display: grid !important;
    grid-template-columns: auto;
    width: auto;
  }

  .class-grid-portfolio > .class-portfolioSelect,
  .class-grid-portfolio
    > .MuiGrid-root.makeStyles-layoutContainer-26.MuiGrid-container.MuiGrid-item.MuiGrid-grid-xs-12,
  .class-grid-portfolio > div:nth-child(2) {
    grid-column: 1;
    margin-left: 15px;
  }

  .container-portfolio-card {
    margin-left: 0px;
  }
}

@media only screen and (max-width: 400px) {
  .container-portfolio-card {
    margin-left: -5px;
  }
}

@media only screen and (min-width: 550px) and (max-width: 700px) {
  .class-column4PortfolioContent {
    margin-left: 30px;
    width: 85%;
  }
}
