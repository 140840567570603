@import 'variable.scss';

.class-blogSlug,
.class-evergreenPage {
  .portfolio-career-title {
    max-height: 75px;
  }

  .class-container-link {
    display: block;
    margin-bottom: 20px;

    svg.MuiSvgIcon-root {
      color: $color-cream;
    }

    div#panel1a-header {
      background: $color-orange;
      height: 48px !important;
      color: $color-cream;
      margin-top: 10px;
    }

    div#panel1a-header.MuiAccordionSummary-root.Mui-expanded {
      height: 48px !important;
      background: $color-orange-light;
      color: $color-cream;
    }

    .MuiAccordionDetails-root {
      display: flex;
      flex-direction: column;
      height: 100%;
      max-height: 238px;
      overflow-y: auto;
      padding: 0;

      span {
        padding: 10px 16px;
        background: $color-cream;
      }

      a {
        text-align: left;
        width: 100%;
        font-weight: bolder;
      }
    }
  }

  .class-BlogBody {
    .display-block {
      display: block;
      position: relative;
    }

    .strong {
      font-weight: bold;
    }
    .dropCap span {
      float: left;
      padding: 8px;
      font-size: 59px;
      font-weight: 400;
      line-height: 30px;
      letter-spacing: 0.5px;
      text-transform: capitalize;
    }
    .normal {
      color: #333333;
      padding: 2px 0px 10px 0px;
      font-size: 16px;
      font-weight: 400;
      line-height: 28px;
      white-space: pre-wrap;
      letter-spacing: 0.5px;
    }
    .horizontalRule {
      width: 100%;
      height: 1px;
      border-top: 1px solid rgba(0, 0, 0, 0.12);
      border-left: none;
      border-right: none;
      border-bottom: none;
      margin: 20px 0;
      display: block;
    }
    .h2,
    .h3,
    .h4,
    .h5,
    .h6 {
      display: block;
      font-style: normal;
      font-family: 'IBM Plex Sans', sans-serif;
      font-weight: 600;
    }
    .h2 > span,
    .h3 > span,
    .h4 > span,
    .h5 > span,
    .h6 > span {
      padding-top: 5px;
      margin-top: -10px;
    }
    .h2 {
      font-size: 2.375rem;
      letter-spacing: -0.5px;
    }
    .h3 {
      font-size: 2rem;
    }
    .h4 {
      font-size: 1.7rem;
    }
    .h5 {
      font-size: 1.4rem;
    }
    .h6 {
      font-size: 1.1rem;
    }
    .listItem-bullet {
      display: list-item;
      margin-left: 20px;
    }
    span.normal.bullet > span {
      font-weight: bold !important;
      display: list-item;
      width: fit-content;
    }
    > span.normal.bullet:nth-child(2) {
      display: inherit !important;
    }
    .class-button-link {
      background: $color-orange;
      color: #fff;
      font-weight: 600;
      padding: 10px 20px;
      text-decoration: none;
    }
    .class-blog-link ~ span,
    .class-blog-link ~ a,
    .class-button-link ~ span {
      display: none !important;
    }

    .centerAlign {
      text-align: center;
      margin: auto;
      justify-content: center;
      display: flex;
    }
    .primaryTextColor {
      color: $color-orange;
    }
    .backgroundHighlightPeach {
      background: $backgroundHighlightPeach;
      padding: 5px 0px;
    }
    .containerQuote {
      display: flex;
      align-items: center;
    }
    .quoteTextRender {
      font-family: 'IBM Plex Sans';
      font-weight: bold;
      font-size: 28px;
      text-align: justify;
    }
    .lineQuote {
      border-left: 7px solid black;
      padding-right: 20px;
    }
    .containerNumber {
      align-items: baseline;
      display: flex;
    }
    .numberListItem {
      font-size: 16px;
      font-style: normal;
      margin-top: 6px;
      font-weight: 400;
      margin-right: 16px;
      letter-spacing: 0.5px;
    }
    .largeBodyText {
      font-size: 21px;
      font-family: 'DTL Documenta';
      letter-spacing: 0;
      white-space: pre-wrap;
    }
    .imageBlogPost {
      max-width: 100%;
      max-height: 100%;
    }
  }
}

.portfolio-career-container {
  .class-column2BlockContent {
    .class-BlogBody {
      .titleH + div {
        margin-top: -15px;
      }
    }
  }
}
.class-display-none {
  display: none;
}
.MuiGrid-root.MuiGrid-container.MuiGrid-item.MuiGrid-direction-xs-column.MuiGrid-grid-xs-12.MuiGrid-grid-md-6
  > .MuiGrid-root.MuiGrid-item:last-child {
  width: 100%;
}

// @media only screen and (max-width: 500px) {
//   .class-blogSlug .class-BlogBody {
//     .h2 {
//       font-size: 1.25rem !important;
//     }
//     .h3 {
//       font-size: 1rem !important;
//     }
//     .h4 {
//       font-size: 0.8rem !important;
//     }
//     .h5 {
//       font-size: 0.6rem !important;
//     }
//     .h6 {
//       font-size: 0.4rem !important;
//     }
//   }
// }

@media only screen and (max-width: 900px) {
  .class-evergreenPage {
    margin-right: 35px !important;

    .portfolio-career-title {
      max-height: 50px;
    }
  }
}

@media only screen and (max-width: 445px) {
  .class-evergreenPage {
    .portfolio-career-title {
      max-height: 100px;
    }
  }
  .portfolio-career-container {
    .class-BlogBody {
      .titleH {
        max-height: 100px;
      }
    }
  }
}

@media only screen and (max-width: 400px) {
  .class-evergreenPage {
    margin-left: -35px !important;
  }
}
